<template>
  <div>
    <el-dialog title="纠错"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="600px"
               :before-close="handleClose">
      <el-form :model="form"
               label-width="80px">
        <el-form-item label="纠错:">
          <el-input type="textarea"
                    :rows="5"
                    v-model="form.comment"
                    placeholder="请输入纠错内容"
                    style="width:100%" />
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary"
                   @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      form: {

      }
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },

    save () {
      this.$http({
        url: '/api/v1/error/report',
        method: 'post',
        data: this.form
      }).then(res => {
        this.handleClose()
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>