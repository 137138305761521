<template>
  <div>
    <el-dialog title="回复帖子"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               :width="width"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               ref="formRef"
               label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="回复内容:"
                          prop="discuss_content">
              <vue-ueditor-wrap v-model="form.discuss_content"
                                class="ueditor_wrap"
                                key="reply"
                                :config="editorConfig"></vue-ueditor-wrap>
              <!-- <el-input clearable
                        placeholder="请输入内容"
                        v-model.trim="form.discuss_content"
                        type="textarea"
                        :rows="5" /> -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary"
                   @click="reply">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      plateList: [],
      dialogVisible: false,
      discuss_id: '',
      form: { discuss_content: '', discuss_parent_id: '' },
      rules: {
        discuss_content: [
          { required: true, message: '请选择', trigger: 'change' },
        ]
      },
      width: '60vw',
      editorConfig: {}
    }
  },
  mounted () {
    if (document.body.clientWidth < 1200) {
      this.width = '700px'
    }
    this.editorConfig = JSON.parse(JSON.stringify(this.$config))
    this.editorConfig.zIndex = "9999"
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
      this.$parent.activeReply = false
    },

    reply () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        this.$http({
          url: '/api/v1/discuss/reply',
          method: 'post',
          data: this.form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '回复成功!',
            type: 'success'
          });
          this.handleClose()
          this.$parent.getInfo()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
.ueditor_wrap {
  z-index: 99999;
}
</style>